<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <b-overlay :show="is_load">
                            <b-form @submit="saveMinhaConta">
                                <b-form-group label="Nome" class="required">
                                    <b-form-input v-model="form.name" placeholder="" required></b-form-input>
                                </b-form-group>
                                <b-form-group label="E-mail" class="required">
                                    <b-form-input type="email" v-model="form.email" placeholder="" required></b-form-input>
                                </b-form-group>
                                <b-form-group label="Senha" class="">
                                    <b-form-input type="password" v-model="form.password" placeholder=""></b-form-input>
                                </b-form-group>
                                <b-form-group label="Confirmação de senha" class="">
                                    <b-form-input type="password" v-model="form.password_confirm" placeholder=""></b-form-input>
                                </b-form-group>

                                <b-button variant="outline-primary" size="lg" type="submit">Salvar cadastro</b-button>

                            </b-form>
                        </b-overlay>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>

<script>

import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";
import Vue from "vue";
import axios from "axios";
import firebase from 'firebase/app';

export default {
    name: "minhaConta",
    components: {
        Layout,
        PageHeader
    },
    data() {
        return {
            is_load: false,
            form: {
                name: '',
                email: '',
                password: '',
                password_confirm: '',
            },
            title: "Minha conta",
            items: [
                {
                    text: "Home"
                },
                {
                    text: "Minha conta",
                    active: true
                }
            ]
        };
    },
    mounted() {
        this.is_load = true;
        firebase.auth().onAuthStateChanged(user => {
            this.is_load = false;
            if (user) {
                this.form.name = user.displayName;
                this.form.email = user.email;
                this.form.uid = user.uid;
            }
        });
        // this.form.name = this.item.displayName;
        // this.form.email = this.item.email;
    },
    methods: {
        saveMinhaConta(evt) {
            evt.preventDefault();

            if (this.form.email === '') {
                Vue.swal({
                    title: 'E-mail inválido',
                    text: 'Por favor, digite um E-mail válido',
                    type: 'error',
                    confirmButtonText: 'Ok'
                })
                return;
            }
            if (this.form.password != this.form.password_confirm) {
                Vue.swal({
                    title: 'Senhas não são iguais',
                    text: 'Por favor, digite novamente as senhas, devem ser iguais',
                    type: 'error',
                    confirmButtonText: 'Ok'
                })
                return;
            }

            this.is_load = true;
            console.log('this.form', this.form)
            axios.post('usuarios', this.form, {headers: {"Access-Control-Allow-Origin": true}}).then((response) => {
                console.log(response)
                Vue.swal({
                    title: 'Cadastro atualizado com sucesso',
                    type: 'success',
                    confirmButtonText: 'Ok'
                });
                this.is_load = false;
            }).catch((erro) => {
                console.log('erro erro', erro)
                this.is_load = false;
                Vue.swal({
                    title: 'Erro ao atualizar cadastro',
                    text: erro.response.data.erro,
                    type: 'error',
                    confirmButtonText: 'Ok'
                })
            })


        }
    }
}
</script>

<style scoped>

</style>